/* eslint-disable multiline-ternary */
import { NextPage } from 'next';
import { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { APP_IMG } from 'src/constants/index';

import { NextRouter, useRouter } from 'next/router';
import { dayDataSet, splitRote } from 'src/utils/helper';

import _ from 'lodash';
import dynamic from 'next/dynamic';
import { SHOW_COMMEMORATE } from 'src/constants';
import { GET } from 'src/services';
import { convertDatasets } from 'src/utils/datasets';
const SectionByteArk = dynamic(import('src/components/sections/section-byteark'));

type Props = { data: any };
const SectionLive: NextPage<Props> = ({ data }) => {
  const router: NextRouter = useRouter();
  const [timeTitel, setTimeText] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    const resData = async () => {
      try {
        const fetchDataSet = await GET('/api/datasets');
        if (!_.isEmpty(fetchDataSet)) {
          let resData;
          let resLink;

          // FILTER DATE
          const dateCheck = dayDataSet(new Date());
          resData = _.find(fetchDataSet, { topic: dateCheck })?.dataSet || [];
          resLink = _.find(fetchDataSet, { topic: 'รายการสด' })?.dataSet || [];
          // resLink = resLink[0]?.value ?? null;
          setLink(_.find(resLink, (data: any) => data?.key === 'ลิงค์')?.value);

          // FILTER TIME
          const timeCheck = dayDataSet(null, true, new Date());
          // FILTER DATA
          let arryStart: any = (!_.isEmpty(resData)) && _.filter(resData, (data: any) => data.key <= timeCheck);
          let startElement = arryStart[arryStart.length - 1];
          // SPLIT DATA
          const splitTitle = startElement?.value?.split('/');
          // SET DATA
          setTimeText(splitTitle);
        }
      } catch (err) {
        console.log('error fetching data ==> section live', err);
      }
    };
    // call the function
    resData().catch(console.error);

  }, [router.isReady]);

  const closeItem = convertDatasets(data[0], 'ปิดผังรายการ', true);
  const centerItem = convertDatasets(data[0], 'จัดตรงกลาง', true);
  const bgLive = convertDatasets(data[0], 'พื้นหลัง', true);

  return (
    <>
      {!_.isEmpty(data) && <SectionWrapper style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }} bgLive={bgLive} >

        {/* <img src={`${APP_IMG}/images/section-live-us.png`} alt='live' title='live' width='100%' height='100%' /> */}
        <div className='container container-live'>
          {closeItem === 'false' ? <div className='section-live-text'>
            {/* <p>NATION</p> */}
            <img className='logo' src={convertDatasets(data?.[0], 'logo', true)} alt='logo' title='logo' width='100%' height='100%' />
            {_.map(timeTitel, (itme, index) => (
              <p key={index}>{itme}</p>
            ))}
            <a href={link}>
              <span>{'ผังรายการ'}</span>
            </a>
          </div> :
            centerItem !== 'true' && <div className='section-live-empty '></div>}
          <div className='section-live '>
            <a href={`${splitRote(router)}/category/nationtv/live`}>
              <div className='live-button'>
                <svg x='0px' y='0px' width='17px' height='15.8px' viewBox='0 0 17 15.8'>
                  <path d='M8.5,6.8c-0.9,0-1.7,0.8-1.7,1.7c0,0.9,0.8,1.7,1.7,1.7c0.9,0,1.7-0.8,1.7-1.7C10.2,7.6,9.5,6.8,8.5,6.8z'></path>
                  <path d='M13.6,8.5c0-2.8-2.2-5.1-5-5.2c-2.8,0-5.1,2.2-5.2,5c0,0,0,0.1,0,0.1c0,1.8,1,3.5,2.6,4.4l0.9-1.4c-1.6-1-2.1-3-1.2-4.6s3-2.1,4.6-1.2c1,0.6,1.7,1.7,1.7,2.9c0,1.2-0.6,2.3-1.7,2.9l0.9,1.4C12.7,12,13.6,10.3,13.6,8.5L13.6,8.5z'></path>
                  <path d='M8.5,0C3.8,0,0,3.8,0,8.5c0,3,1.6,5.8,4.3,7.3l0.9-1.4c-3.3-1.9-4.4-6.1-2.5-9.3s6.1-4.4,9.3-2.5c2.1,1.2,3.4,3.5,3.4,5.9c0,2.4-1.3,4.7-3.4,5.9l0.9,1.4c4.1-2.3,5.5-7.5,3.1-11.6C14.4,1.6,11.6,0,8.5,0z'></path>
                </svg>
                <p>ดูทีวีออนไลน์ เนชั่นทีวี</p>
              </div>
            </a>
            {/* <div id={`${NEXT_PUBLIC_TENCENT_CLOUD_DIV_ID || ""}`} style={{ width: '100%', height: 'auto' }} className='video-live'></div> */}
            {/* <object type='text/html' data={`${APP_IMG}/uploads/${DAILYMOTION_HTML}`} width='100%' height='100%' aria-label='section-live'></object> */}
            <SectionByteArk />

          </div>
        </div>
      </SectionWrapper>}

    </>
  );
};

const SectionWrapper = styled.section<{ bgLive: string }>`
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  margin: 30px 0;
  background-image: url(${props => props?.bgLive});
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  background-color: #FFFFFFFF;
  #byteark-player-video-player, #video-player {
    position: absolute;
    top: 17px;
    left: 20px;
    width: 94%;
    padding-top: 52%;
    margin: auto;
    z-index: 1;
  }
  #video-player {
    padding: 0;
  }
  @media (min-width: 1440px) {
    /* height: 656px; */
    height: 720px;
    max-width: 1980px;
    margin: auto;
  }
  .container-live {
    display: flex;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    .section-live-text,.section-live-empty {
      width: 30%;
      text-align: center;
      margin: auto;
      .logo {
        width: 100%;
        max-width: 150px;
        // filter: drop-shadow(2px 1px 1px black);
      }

      span {
        padding: 5px 20px;
        background: #1e3256;
        color: #fff;
        font-size: 18px;
        font-weight: 300;
        margin: 30px auto 0 auto;
        display: block;
        width: max-content;
      }
      
      p {
        color: #27398B;
        font-size: 36px;
        font-weight: 300;
        @media (max-width: 1024px) {
          font-size: 30px;
        }
        &:nth-child(2) {
          font-size: 44px;
          font-weight: 700;
          @media (max-width: 1024px) {
            font-size: 36px;

            font-weight: 500;
          }
        }
        &:not(:nth-child(2),:nth-child(1)) {
          font-size: 24px;
          font-weight: 300;
          font-style: italic;
          @media (max-width: 1024px) {
            font-size: 20px;
          }
        }
        &:last-child {
          font-size: 1110px;
          margin-bottom: 30px;
        }
      }
    }
  }
  .section-live {
    display: block;
    position: relative;
    width: 768px;
    padding-top: 38%;
    background: url(${APP_IMG}/images/border-background.png);
    background-repeat: no-repeat;
    margin: auto;
    .live-button {
      display: flex;
      align-items: center;
      text-align: center;
      background: #eb212d;
      position: absolute;
      top: -30px;
      right: 40px;
      padding: 0px 8px;
      font-size: 20px;
      text-transform: uppercase;
      color: #fff;
      padding: 4px 6px;
      svg {
        margin-right: 15px;
      }
      svg path {
        fill: #fff;
        /* Copy this @keyframes block to your CSS*/
        @keyframes signalanimate {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        &:nth-of-type(1) {
          -webkit-animation: signalanimate 2s infinite;
          animation: signalanimate 2s infinite;
        }
        &:nth-of-type(2) {
          -webkit-animation: signalanimate 2s infinite;
          animation: signalanimate 2s infinite;
          -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
        }
        &:nth-of-type(3) {
          -webkit-animation: signalanimate 2s infinite;
          animation: signalanimate 2s infinite;
          -webkit-animation-delay: 1s;
          animation-delay: 1s;
        }
      }
    }
    .video-live {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 30px 40px 60px 30px;
      @media (max-width: 1024px) {
        padding: 0;
      }
    }
  }
`;

export default SectionLive;
